import React, { useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { CustomIcons } from "./CustomIcons";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { setDefaultSetting, store } from "../store";
import { useSnapshot } from "valtio";
import { CustomSaleSupportIcons } from "./CustomSaleSupportIcons";

export function SaleMode() {
    const { t } = useTranslation();

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const { defaultSetting } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={t(e.selectedRow.display_name)} />
            ),
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 1,
            columnName: t("Sales method"),
            customJsx: (e: any) => <span>{t(e.selectedRow["Mode of sale"])}</span>,
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Sale support"),
            customJsx: (e: any) => <CustomSaleSupportIcons e={e} />,
            filterName: "support_vente",
            canFilter: true,
        },

        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => <CustomIcons e={e} />,
            disableFilter: false,
        },
    ];

    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            saleMode: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Sales method")}</h2>
            <DynamicTable
                canMovedCheckboxLeftOnExpand
                url={"/DefaultSetting/saleMode.json"}
                setData={setData}
                canSelect
                customSelect
                canResize
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                actionColumn={() => <></>}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                elevationTable={1}
                minHeight={"450px"}
                maxHeight={"450px"}
                name="tva"
            />
        </div>
    );
}
