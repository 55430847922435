import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { DefaultSettingModal } from "..";
import { useSnapshot } from "valtio";
import { store } from "../DefaultModalContentWrapper/store";
import ErrorToast from "@components/Common/ErrorTost";
import { checkFavoriteConditions } from "./helpers";
import "./index.css";

type PropsType = {
    children: React.ReactNode;
    setIsConfigurationModalOpened: Function;
    isConfigurationModalOpened: boolean;
    setIsLoading: Function;
    numberClick: any;
    setNumberClick: any;
    setLimit: any;
};

export function DefaultModalWrapper({
    children,
    setIsConfigurationModalOpened,
    isConfigurationModalOpened,
    setIsLoading,
    numberClick,
    setNumberClick,
    setLimit,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { defaultSetting } = useSnapshot(store);

    const handleSaveClick = () => {
        setLimit(2);
        const {
            missingFavorites,
            missingSaleSupport,
            tvaHasFavoriteWithStatusTrue,
            languageHasFavoriteWithStatusTrue,
            saleModeEmpty,
            paymentMethodEmpty,
            tvaHasFavorite,
            languageHasFavorite,
            saleSupportHasFavorite,
            saleModeHasFavorite,
        } = checkFavoriteConditions(defaultSetting, t);

        if (missingFavorites.length > 0) {
            const message = t(
                "Attention, please add at least one {{missingFavorites}} as favorite.",
                { missingFavorites: missingFavorites.join(", ") }
            );
            ErrorToast(message);
        }
        if (missingSaleSupport.length > 0) {
            const message = t(
                "Attention, a {{missingSaleSupport}} cannot be added without a sales support. Please check your sales support settings.",
                { missingSaleSupport: missingSaleSupport.join(", ") }
            );
            ErrorToast(message);
        }

        if (
            (tvaHasFavorite &&
                languageHasFavorite &&
                saleSupportHasFavorite &&
                saleModeHasFavorite &&
                !saleModeEmpty &&
                !paymentMethodEmpty) ||
            (tvaHasFavoriteWithStatusTrue === undefined &&
                !saleModeEmpty &&
                !paymentMethodEmpty) ||
            (languageHasFavoriteWithStatusTrue === undefined &&
                !saleModeEmpty &&
                !paymentMethodEmpty)
        ) {
            setIsOpen(true);
        }
    };

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={numberClick === 0 && isConfigurationModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={() => {
                        setIsConfigurationModalOpened(
                            !isConfigurationModalOpened
                        );
                        setNumberClick(1);
                    }}
                    className="text-uppercase"
                >
                    {t("Default Configuration Validation")}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    <CustomSecondaryColorButton
                        className="w-20 kds_btn_validateTemplate"
                        rounded
                        variant="primary"
                        onClick={() => {
                            setIsConfigurationModalOpened(
                                !isConfigurationModalOpened
                            );
                            setNumberClick(1);
                        }}
                    >
                        {t("Annuler")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        className="w-20 kds_btn_validateTemplate"
                        rounded
                        variant="primary"
                        onClick={handleSaveClick}
                    >
                        {t("Save")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>

            {isOpen ? (
                <DefaultSettingModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setIsConfigurationModalOpened={
                        setIsConfigurationModalOpened
                    }
                    setIsLoading={setIsLoading}
                />
            ) : null}
        </React.Fragment>
    );
}
