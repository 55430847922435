import { proxy } from "valtio";
import { devtools } from "valtio/utils";

type StoreGeneralType = {
    ticketHeaderSaleModeBold: boolean;
    ticketHeaderSaleModeBlackBackround: boolean;
    ticketHeaderName_phone_number_bold: boolean;
    ticketHeaderPathCustomer: string;
    ticketHeaderPathKitchen: string;
    ticketHeaderLogoCustomer: boolean;
    ticketHeaderLogoKitchen: boolean;
    NumberCustomer: number;
    NumberTicketsKitchen: number,
    typeLogoCustomer:string,
    typeLogoKitchen:string
    ticketHeaderStyleOrderNumber: boolean;
    printerInformationMode: boolean;
    printerBold: boolean;
};

export const StoreGeneral = proxy<StoreGeneralType>({
    ticketHeaderSaleModeBold: false,
    ticketHeaderSaleModeBlackBackround: false,
    ticketHeaderName_phone_number_bold: false,
    ticketHeaderPathCustomer: "",
    ticketHeaderPathKitchen: "",
    ticketHeaderLogoCustomer: false,
    ticketHeaderLogoKitchen: false,
    NumberCustomer: 1,
    NumberTicketsKitchen: 1,
    typeLogoCustomer:"",
    typeLogoKitchen:"",
    ticketHeaderStyleOrderNumber: false, 
    printerInformationMode: false,
    printerBold: false,
});
devtools(StoreGeneral);

export function setTicketHeaderSaleModeBold(
    ticketHeaderSaleModeBold: boolean
): void {
    StoreGeneral.ticketHeaderSaleModeBold = ticketHeaderSaleModeBold;
}

export function setTicketHeaderStyleOrderNumber(
    ticketHeaderStyleOrderNumber: boolean
): void {
    StoreGeneral.ticketHeaderStyleOrderNumber = ticketHeaderStyleOrderNumber;
}
export function setPrinterBold(printerBold: boolean): void {
    StoreGeneral.printerBold = printerBold;
}

export function setPrinterInformationMode(
    printerInformationMode: boolean
): void {
    StoreGeneral.printerInformationMode = printerInformationMode;
}
export function setTicketHeaderSaleModeBlackBackround(
    ticketHeaderSaleModeBlackBackround: boolean
): void {
    StoreGeneral.ticketHeaderSaleModeBlackBackround = ticketHeaderSaleModeBlackBackround;
}
export function setTicketHeaderName_phone_number_bold(
    ticketHeaderName_phone_number_bold: boolean
): void {
    StoreGeneral.ticketHeaderName_phone_number_bold = ticketHeaderName_phone_number_bold;
}
export function setTicketHeaderPathCustomer(
    ticketHeaderPathCustomer: string
): void {
    StoreGeneral.ticketHeaderPathCustomer = ticketHeaderPathCustomer;
}
export function setTicketHeaderPathKitchen(
    ticketHeaderPathKitchen: string
): void {
    StoreGeneral.ticketHeaderPathKitchen = ticketHeaderPathKitchen;
}
export function setTicketHeaderLogoCustomer(
    ticketHeaderLogoCustomer: boolean
): void {
    StoreGeneral.ticketHeaderLogoCustomer = ticketHeaderLogoCustomer;
}
export function setTicketHeaderLogoKitchen(
    ticketHeaderLogoKitchen: boolean
): void {
    StoreGeneral.ticketHeaderLogoKitchen = ticketHeaderLogoKitchen;
}
export function setAutomaticPrintingNumberCustomer(
    NumberCustomer: number
): void {
    StoreGeneral.NumberCustomer = NumberCustomer;
}
export function setAutomaticPrintingNumberTickets(
    NumberTicketsKitchen: number
): void {
    StoreGeneral.NumberTicketsKitchen = NumberTicketsKitchen;
}
export function setTypeLogoCustomer(
    typeLogoCustomer: string
): void {
    StoreGeneral.typeLogoCustomer = typeLogoCustomer;
}
export function setTypeLogoKitchen(
    typeLogoKitchen: string
): void {
    StoreGeneral.typeLogoKitchen = typeLogoKitchen;
}
export function resetStore() {
    StoreGeneral.ticketHeaderSaleModeBold = false;
    StoreGeneral.ticketHeaderSaleModeBlackBackround = false;
    StoreGeneral.ticketHeaderName_phone_number_bold = false;
    StoreGeneral.ticketHeaderLogoCustomer = false;
    StoreGeneral.ticketHeaderLogoKitchen = false;
    StoreGeneral.ticketHeaderPathCustomer = "";
     StoreGeneral.ticketHeaderPathKitchen= "";
    StoreGeneral.NumberCustomer= 1;
    StoreGeneral.NumberTicketsKitchen=1;
}

export default StoreGeneral;
