import React from "react";
import { useTranslation } from "react-i18next";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { useSnapshot } from "valtio";
import { store as defaultSettingStore } from "./DefaultModalContentWrapper/store";
import {
    filterActiveItems,
    generateClassAndText,
    getPeripheralTypes,
    parseApplicationsText,
    transformData,
    transformSaleSupport,
} from "@helpers/general";
import { toast } from "react-toastify";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { store } from "@components/VerticalLayout/store";

export function SaveButtonComponent({
    setIsOpen,
    isOpen,
    setIsConfigurationModalOpened,
    setIsLoading,
}: any) {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(defaultSettingStore);
    const { userID, franchiseID, operatorID, oneShop, shopID } = useSnapshot(
        store
    );
    const uuidUser = localStorage.getItem("uuidUser");

    const filteredTva = filterActiveItems(defaultSetting.tva?.data, "status");
    const filteredSaleMode = filterActiveItems(
        defaultSetting.saleMode?.data,
        "status"
    );
    const filteredLanguage = filterActiveItems(
        defaultSetting.language?.data,
        "status"
    );
    const filteredSaleSupport = filterActiveItems(
        defaultSetting.saleSupport?.data,
        "status"
    );
    const filteredPaymentMethod = filterActiveItems(
        defaultSetting.paymentMethod?.data,
        "status"
    );
    const filteredCurrency = filterActiveItems(
        defaultSetting.currency?.data,
        "status"
    );

    const [, applicationsText] = generateClassAndText(
        defaultSetting.applications?.data || [],
        "Application"
    );
    const [, peripheralText] = generateClassAndText(
        defaultSetting.peripheral?.data || [],
        "Péripheriques"
    );

    async function handleApplicationValidateButtonClickEvent() {
        setIsOpen(!isOpen);
        setIsConfigurationModalOpened(false);
        setIsLoading(true);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting`;
        const { kioskCount, kdsCount } = parseApplicationsText(
            applicationsText
        );

        const peripheral = getPeripheralTypes(
            defaultSetting.peripheral?.data || []
        );

        let savedData = {
            userId: userID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            shopId: oneShop ? shopID : "0",

            defaultSetting: {
                tva: filteredTva,
                language: transformData(filteredLanguage),
                saleMode: transformData(filteredSaleMode),
                saleSupport: transformSaleSupport(filteredSaleSupport),
                paymentMethod: transformSaleSupport(filteredPaymentMethod),
                currency: transformData(filteredCurrency),
                ...(applicationsText !== "vide" && {
                    kioskCount: kioskCount,
                    kdsCount: kdsCount,
                }),
                ...(peripheralText !== "vide" && {
                    peripheral: peripheral,
                }),
            },
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        toast.success(
                            `${t(
                                "The default configuration has been successfully applied."
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <CustomMainColorButton
            className="w-20 kds_btn_validateTemplate"
            rounded
            variant="primary"
            onClick={() => {
                handleApplicationValidateButtonClickEvent();
            }}
        >
            {t("Save")}
        </CustomMainColorButton>
    );
}
