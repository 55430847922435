import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledSelectInput,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { v4 } from "uuid";
import { diff } from "deep-object-diff";

import { store } from "@components/VerticalLayout/store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import {
    store as moneticsStore,
    setActions,
    setIsActionsClicked,
    setLimit,
} from "@pages/Peripherals/Monetics/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { verifyIfPeripheralAssociate } from "../../../../helpers/peripheralsHelpers";
import { checkIpIfExist, searchPosInArrayObject } from "@helpers/general";
import {
    AdyenCurrencies,
    SPEEDS,
    ipMatchWord,
    numberPositiveMatch,
} from "../../../../constants";
import ErrorToast from "@components/Common/ErrorTost";
import { AdyenConfiguration } from "./AdyenConfig";
import { ValinaConfig } from "./ValinaConfig";
import { NeptingConfig } from "./NeptingConfig";
import { ValinaSwissConfig } from "./ValinaSwisseConfig";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type MoneticsFormType = {
    setIsAddMoneticsClicked: any;
    isAddMoneticsClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    editedData: React.SetStateAction<any>;
    setIsAddNewMoneticsButtonClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isAddNewMoneticsButtonClicked: boolean;
    setEditedData: Function;
    idShop: string;
    isEdited: boolean;
    setIsEdited: Function;
    selectMark: boolean;
    setSelectMark: Function;
    inputDesignation: boolean;
    setInputDesignation: Function;
    setInputIp: Function;
    InputIp: boolean;
    shopIds?: any;
    tableData?: any;
    setIsUpdateOperation: Function;
    setIpExist: Function;
    ipExist: boolean;
    isConsult: boolean;
    setIsConsult: Function;
    selectedRows: any;
    isModalAssociate?: boolean;
};

export default function MoneticsApplicationForm({
    setIsAddMoneticsClicked,
    isAddMoneticsClicked,
    setDataIsUpdated,
    editedData,
    isAddNewMoneticsButtonClicked,
    idShop,
    setEditedData,
    isEdited,
    setIsEdited,
    selectMark,
    setSelectMark,
    inputDesignation,
    setInputDesignation,
    setInputIp,
    InputIp,
    shopIds,
    tableData,
    setIsUpdateOperation,
    setIpExist,
    ipExist,
    isConsult,
    setIsConsult,
    selectedRows,
    isModalAssociate,
}: MoneticsFormType) {
    const {
        userID,
        resourceIp,
        franchiseID,
        operatorID,
        shopID,
        oneShop,
    } = useSnapshot(store);
    const { isActionsClicked } = useSnapshot(moneticsStore);
    const { t } = useTranslation();
    const IDUSER = userID;
    const FRANCHISEID = franchiseID;
    const paramsTypes: {
        label: string;
        value: string;
    }[] = [t("Check"), t("Ticket restaurant"), t("Bank card")].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const payment_method: {
        label: string;
        value: string;
    }[] = [
        { label: t("TPE configuration"), value: "1" },

        { label: t("payment disabled"), value: "4" },
        { label: t("payment enabled"), value: "3" },
    ];
    const paymentMethods: {
        label: string;
        value: string;
    }[] = [
        { label: "TPE configuration", value: "1" },

        { label: "payment disabled", value: "4" },
        { label: "payment enabled", value: "3" },
    ];

    const speeds: LocalOptionsType[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });
    const ports: LocalOptionsType[] = Array.from(
        { length: 256 },
        (_, i) => "COM" + (i + 1)
    ).map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const uuidUser = localStorage.getItem("uuidUser");

    const typeOfConfig: {
        label: string;
        value: string;
    }[] = ["ERT 10", "ERT 45", "ERT 46", "Autre"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const typeOfTerminal: {
        label: string;
        value: string;
    }[] = [
        { label: t("Automate kiosk (Pre-authorization)"), value: "25" },
        { label: t("TPE POS (Debit)"), value: "22" },
    ];
    const pathname = window.location.pathname.toString();
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const matchWordDesignation = new RegExp(/^[\w]+([-_\s]{1}[A-Za-z0-9]+)*$/i);

    const defaultAdyenConfig = {
        merchantAccount: "",
        adyenCryptoversion: "",
        Identifier: "",
        Passphrase: "",
        Keyversion: "",
        communication: "Local",
        webhookServerUrl: "",
        currency: "",
        merchantTicket: false,
        customerTicket: false,
        cleApi: "",
        useName: "",
        pwd: "",
        identifiantBoutique: "",
    };

    const [adyenConfig, setAdyenConfig] = React.useState(defaultAdyenConfig);

    const [portSelectInput, setPortSelectInput] = React.useState<boolean>(
        false
    );
    const [error] = React.useState(false);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [SelectInput, setSelectInput] = React.useState<boolean>(false);
    const [speedSelectInput, setSpeedSelectInput] = React.useState<boolean>(
        false
    );
    const [selectInputPM, setSelectInputPM] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [adyenIpExist, setAdyenIpExist] = React.useState<boolean | undefined>(
        false
    );
    const [paymentMethod, setPaymentMethod] = React.useState<any>({
        label: t("payment disabled"),
        value: "3",
    });
    const numberMatchWord = new RegExp(/^[1-9]\d*$/);
    const [designation, setDesignation] = React.useState<string>("");
    const [mark, setMark] = React.useState<string>("");
    const [reference, setReference] = React.useState<string>("");
    const [remarque, setRemarque] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [typeConfig, setTypeConfig] = React.useState<any>("");
    const [typeTerminal, setTypeTerminal] = React.useState<any>("");
    const [identifier, setIdentifier] = React.useState<string>("");
    const [nomTpe, setNomTpe] = React.useState<string>("");
    const [merchantCode, setMerchantCode] = React.useState<string>("");
    const [paymentType, setPaymentType] = React.useState<string>("");
    const [deviceCode, setDeviceCode] = React.useState<string>("");
    const [timeout, setTimeOut] = React.useState<string>("120");
    const [inputTimeout, setInputTimeout] = React.useState<boolean>(false);
    const [url, setUrl] = React.useState<string>("");
    const [port, setPort] = React.useState<string>("8888");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [speed, setSpeed] = React.useState<number | string>("");
    const [kiss, setKiss] = React.useState<boolean>(true);
    const [ipAddress, setIpAddress] = React.useState<string>("");
    const [webhook, setWebhook] = React.useState<string>("");
    const isProductionDomain =
        process.env.REACT_APP_API_V2_URL &&
        process.env.REACT_APP_API_V2_URL.includes("etk360");
    const [mode, setMode] = React.useState<string>(
        isProductionDomain ? "Production" : "Test"
    );
    const [InputPort, setInputPort] = React.useState<boolean>(false);
    const [InputCode, setInputCode] = React.useState<boolean>(false);
    const [InputUrl, setInputUrl] = React.useState<boolean>(false);
    const [inputSetupType, setInputSetupType] = React.useState<boolean>(false);
    const [inputTerminalType, setInputTerminalType] = React.useState<boolean>(
        false
    );
    const [inputWebhook, setInputWebhook] = React.useState<boolean>(false);
    const [inputMode, setInputMode] = React.useState<boolean>(false);
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [currency, setCurrency] = React.useState("");
    const [autoValidate, setAutoValidate] = React.useState({
        label: t("True"),
        value: t("True"),
    });
    const [idIntegrator, setIdIntegrator] = React.useState("");
    const [inputCurrency, setInputCurrency] = React.useState<boolean>(false);
    const [inputIdIntegrator, setInputIdIntegrator] = React.useState<boolean>(
        false
    );
    const [inputPortAdyen, setInputPortAdyen] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [IDAdyen, setIDAdyen] = React.useState([]);

    const [selectedIdAdyen, setSelectedIdAdyen] = React.useState({
        label: "",
        value: "",
    });
    const [isShopHasAdyen, setIsShopHasAdyen] = React.useState<boolean>(false);
    const [merchantAccountId, setMerchantAccountId] = React.useState<string>(
        ""
    );
    function moneticIsExistInShop(el: string, data: any) {
        let res: boolean = false;
        let shopData = tableData.data;
        let dataMoneticsShop: any = [];
        if (shopData.length > 0) {
            if (shopData[0][t("Shop")] === undefined) {
                dataMoneticsShop = shopData;
            } else {
                let posDataMoneticsShop = searchPosInArrayObject(
                    shopData,
                    "shopId",
                    el
                );
                dataMoneticsShop = shopData[posDataMoneticsShop].subRows;
            }
        }
        for (let i = 0; i < dataMoneticsShop.length; i++) {
            let elt = dataMoneticsShop[i];
            let elmData = data.data[0];
            if (elmData.designation === elt["Désignation"]) {
                res = true;
            }
        }
        return res;
    }
    async function isCurrencyExistInShop(shopId: string) {
        toast.dismiss();
        let currencyExist = "";

        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": i18nextLng,
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.length !== 0) {
                        if (
                            AdyenCurrencies.find(
                                (el: any) =>
                                    el.code === result.data[0][t("Currency")]
                            ) !== undefined &&
                            result.data[0].isActive === true
                        ) {
                            currencyExist = result.data[0][t("Currency")];
                        }
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return currencyExist;
    }

    async function addMonetics() {
        toast.dismiss();
        let hasIpConflict = 0;
        if (shopIds.length === 0) {
            ErrorToast(`${t("Attention ! you must select a store.")!}`);
        } else if (shopIds.length > 0) {
            if (canValidate) {
                if (adyenConfig.merchantAccount === "" && mark === "Adyen") {
                    ErrorToast(
                        t(
                            "To add an Adyen payment terminal, you must fill in the fields of the 'Adyen Configuration.'"
                        )
                    );
                } else {
                    let isNotInclude: boolean = false;
                    setCanValidate(false);
                    let localData: any = [];
                    for (let el of shopIds) {
                        let isCurrencyExist: any = await isCurrencyExistInShop(
                            el
                        );
                        if (
                            (isCurrencyExist !== "" &&
                                mark === "Adyen" &&
                                selectedIdAdyen.value !== "") ||
                            mark !== "Adyen"
                        ) {
                            let data = {
                                userId: userID,
                                shopId: el,
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                uuidUser: uuidUser,
                                data: [
                                    {
                                        id: v4(),
                                        designation: designation?.trim(),
                                        mark: mark,
                                        reference: reference,
                                        note: remarque,
                                        shopId: el,
                                        merchant_code: merchantCode,
                                        device_code: deviceCode,
                                        port: port,
                                        url: url,
                                        kiss: kiss,
                                        speed: speed,
                                        setup_type: typeConfig,
                                        terminal_type: typeTerminal,
                                        ip: ipAddress,
                                        payment_type: paymentType,
                                        payment_method: paymentMethod,
                                        timeout: timeout,
                                        isArchived: false,
                                        webhook: webhook,
                                        adyenIp: selectedIdAdyen.value,
                                        mode: mode,
                                        idIntegrator: idIntegrator,
                                        autoValidate: autoValidate,
                                        currency: currency,
                                        adyenConfig:
                                            mark === "Adyen"
                                                ? {
                                                      ...adyenConfig,
                                                      currency: isCurrencyExist,
                                                  }
                                                : adyenConfig,
                                    },
                                ],
                            };
                            let filteredData = JSON.parse(
                                JSON.stringify(resourceIp)
                            )
                                ?.filter(
                                    (item: any) =>
                                        item?.shopId === el &&
                                        item.ip.trim() !== ""
                                )
                                .map((y: any) => {
                                    return y.ip;
                                });
                            filteredData = [...new Set(filteredData)];

                            if (filteredData.includes(ipAddress)) {
                                hasIpConflict++;
                            }
                            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/monetics`;
                            if (
                                moneticIsExistInShop(el, data) === false &&
                                filteredData.includes(ipAddress) === false
                            ) {
                                localData.push(
                                    fetch(apiUrlAdd, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify(data),
                                    }).then((response) => response.json())
                                );
                            }
                        } else if (isCurrencyExist === "" && mark === "Adyen") {
                            isNotInclude = true;
                        }
                    }

                    try {
                        mutate(
                            `${process.env.REACT_APP_API_V2_URL}/settings/monetics`,
                            await Promise.all(localData).then((result) => {
                                let Err = result.filter((x: any) => {
                                    return x.errorMessage !== "";
                                });

                                if (Err.length > 0) {
                                    ErrorToast(t(Err[0].errorMessage));
                                } else if (
                                    result.length !== 0 &&
                                    result.every(
                                        (el: any) => el.error === false
                                    ) &&
                                    localData?.length > 0
                                ) {
                                    toast.success(
                                        `${t(
                                            "The device is successfully added"
                                        )!}`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            theme: "colored",
                                            icon: (
                                                <CheckboxIcon
                                                    height={25}
                                                    width={25}
                                                    fill="white"
                                                />
                                            ),
                                        }
                                    );
                                }
                                if (hasIpConflict > 0) {
                                    setCanValidate(true);
                                    ErrorToast(
                                        t(
                                            "Please note, the device could not be added to one or more stores. Please check the IP address settings."
                                        )
                                    );
                                }
                                setEditedData([]);
                                resetAllPeripherals();
                                setDataIsUpdated!(true);
                                setIsEdited(false);
                                setCanValidate(true);
                            })
                        );
                    } catch (e) {
                        setCanValidate(true);
                        setDataIsUpdated!(true);
                        toast.error(`${t("There's an error" + error)!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                    if (isNotInclude) {
                        ErrorToast(
                            t(
                                "Caution, the addition could not be successfully completed for one or more stores. Please check the currency settings in the general settings ."
                            )
                        );
                    }
                    setLimit(2);
                }
            }
        }
    }

    async function editMonetics() {
        toast.dismiss();
        if (canValidate) {
            setCanValidate(false);
            const shopId = oneShop ? shopID : idShop;
            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                identifier,
                shopId as string,
                FRANCHISEID,
                userID,
                appId
            );
            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/monetics/configuration?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

            let dataConfig = {
                userId: IDUSER,
                shopId: oneShop ? shopID : idShop,
                franchiseId: FRANCHISEID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: identifier,
                data: [
                    {
                        id: identifier,
                        designation: designation?.trim(),
                        tpe_name: nomTpe,
                        reference: reference,
                        note: remarque,
                        android: "android",
                        merchant_code: merchantCode,
                        speed: speed,
                        device_code: deviceCode,
                        port: port,
                        url: url,
                        shopId: oneShop ? shopID : idShop,
                        ip: ipAddress,
                        payment_type: paymentType,
                        mark: mark,
                        payment_method: paymentMethod,
                        timeout: timeout,
                        kiss: kiss,
                        setup_type: typeConfig,
                        terminal_type: typeTerminal,
                        isArchived: false,
                        webhook: webhook,
                        adyenIp: selectedIdAdyen.value,
                        mode: mode,
                        idIntegrator: idIntegrator,
                        autoValidate: autoValidate,
                        currency: currency,
                        adyenConfig: adyenConfig,
                    },
                ],
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            toast.success(
                                `${t("The device is successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (
                                ifPeripheralAssociateObject.isPeripheralAssociate
                            )
                                setTimeout(RedisPublishPeripheral, 3000);
                            setEditedData([]);
                            resetAllPeripherals();
                            setDataIsUpdated!(true);
                            setIsAddMoneticsClicked(!isAddMoneticsClicked);
                            setIsEdited(false);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function handleOnChangeMark(e: any) {
        setSelectMark(false);
        setPortSelectInput(false);
        setSpeedSelectInput(false);
        setInputUrl(false);
        setInputSetupType(false);
        setInputTerminalType(false);
        setSelectInput(false);
        setInputCode(false);
        setInputPort(false);
        setInputIp(false);
        setMark(e.value);
        setIsEdited(true);
        setSelectedIdAdyen({
            label: "",
            value: "",
        });
        setWebhook("");
        if (e.value === "Valina") {
            setPort("");
        } else {
            setPort("8888");
        }
        setMerchantCode("");
        setSpeed("");
        setIpAddress("");
        setPaymentType("");
        setUrl("");
        setInputDesignation(false);
        setInputCode(false);
        setInputMode(false);
        setInputWebhook(false);
        setSelectInputPM(false);
        setInputTimeout(!numberPositiveMatch.test(timeout) ? true : false);
        setTypeTerminal("");
        e.value === "Valina"
            ? setReference("EXAMPLE 1")
            : e.value === "Nepting"
            ? setReference("S300")
            : setReference("");
    }

    async function handleValidate() {
        if (mark === "") {
            setSelectMark(true);
        }
        if (!ipAddress.match(ipMatchWord)) {
            setInputIp(true);
        }
        if (designation === "") {
            setInputDesignation(true);
        }
        if (speed === "") {
            setSpeedSelectInput(true);
        }
        if (port === "") {
            setPortSelectInput(true);
        }
        if (port === "") {
            setInputPortAdyen(true);
        }
        if (paymentType === "") {
            setSelectInput(true);
        }
        if (port === "") {
            setInputPort(true);
        }
        if (merchantCode === "") {
            setInputCode(true);
        }
        if (url === "") {
            setInputUrl(true);
        }
        if (webhook === "") {
            setInputWebhook(true);
        }
        if (mode === "") {
            setInputMode(true);
        }
        if (Object.keys(paymentMethod).length === 0) {
            setSelectInputPM(true);
        }
        if (timeout === "") {
            setInputTimeout(true);
        }
        if (typeTerminal === "") {
            setInputTerminalType(true);
        }
        if (typeConfig === "") {
            setInputSetupType(true);
        }
        if (idIntegrator === "") {
            setInputIdIntegrator(true);
        }
        if (currency === "") {
            setInputCurrency(true);
        }

        if (
            (mark === "Valina" &&
                designation.length !== 0 &&
                reference !== "" &&
                port !== "" &&
                speed !== "" &&
                paymentMethod !== "" &&
                timeout !== "" &&
                !(
                    (isNameExist &&
                        (shopIds === undefined || shopIds.length === 0)) ||
                    (isNameExist && shopIds.length >= 1)
                ) &&
                designation?.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp) &&
                selectInputPM === false &&
                inputTimeout === false) ||
            (mark === "Nepting" &&
                typeConfig !== "" &&
                typeTerminal !== "" &&
                designation.length !== 0 &&
                reference !== "" &&
                ipAddress !== "" &&
                port !== "" &&
                merchantCode !== "" &&
                url !== "" &&
                paymentType !== "" &&
                Number(port) &&
                ipAddress.match(ipMatchWord) &&
                ipExist === false &&
                !(
                    (isNameExist &&
                        (shopIds === undefined || shopIds.length === 0)) ||
                    (isNameExist && shopIds.length === 1)
                ) &&
                designation?.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp)) ||
            (mark === "Adyen" &&
                designation.length !== 0 &&
                port !== "" &&
                mode !== "" &&
                webhook !== "" &&
                ipAddress !== "" &&
                selectedIdAdyen.value !== "" &&
                ipAddress.match(ipMatchWord) &&
                port.match(numberMatchWord) &&
                webhook.match(numberMatchWord) &&
                ipExist === false &&
                !(
                    (isNameExist &&
                        (shopIds === undefined || shopIds.length === 0)) ||
                    (isNameExist && shopIds.length === 1)
                ) &&
                designation?.trim() !== "" &&
                designation.match(matchWordDesignation)) ||
            (mark === "Valina swiss" &&
                designation.length !== 0 &&
                !(
                    (isNameExist &&
                        (shopIds === undefined || shopIds.length === 0)) ||
                    (isNameExist && shopIds.length === 1)
                ) &&
                designation.trim() !== "" &&
                designation.match(notStartWithSpaceRegExp) &&
                idIntegrator !== "" &&
                currency !== "")
        ) {
            if (editedData.Id) {
                if (
                    isEdited === false &&
                    Object.keys(diff(adyenConfig, editedData.adyenConfig))
                        .length === 0
                ) {
                    setIsModalOpened(!isModalOpened);
                } else {
                    await editMonetics();
                }
            } else {
                await addMonetics();
            }
        }
    }

    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        let ids =
            shopIds.length !== 0 ? shopIds : oneShop ? [shopID] : [idShop];
        ids.forEach(async (shop: any) => {
            try {
                mutate(
                    redisApiUrl,
                    await fetch(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify({
                            shopId: shop,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        });
    }

    function getListMonetics(data: any, shopId: string) {
        let res: any = [];
        if (data.length > 0) {
            if (data[0][t("Shop")] !== undefined) {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    for (let j = 0; j < element.subRows.length; j++) {
                        const el = element.subRows[j];

                        if (el.shopId === shopId) {
                            res.push(el);
                        }
                    }
                }
            } else {
                res = data;
            }
        }
        return res;
    }

    function checkDesignationIfExist(designation: string, data: any) {
        let res = false;
        if (data.length > 0) {
            data.forEach((desData: any) => {
                if (desData[t("Designation")] !== undefined)
                    if (
                        designation?.toUpperCase().trim() ===
                        desData[t("Designation")]?.toUpperCase().trim()
                    ) {
                        res = true;
                    }
            });
        }
        return res;
    }

    const fetchAdyenInfo = async (shopId: string) => {
        try {
            const api = `${process.env.REACT_APP_ACCESS_API_URL}/getAdyenInfo?uidentitie=${shopId}`;
            const response = await fetch(api, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
                method: "GET",
            });

            const data = await response.json();
            let currencyExist: any = await isCurrencyExistInShop(shopId);
            setCurrency(currencyExist);
            if (data.data === null) {
                setAdyenConfig({
                    ...adyenConfig,
                    currency: currencyExist,
                });
            } else {
                setMerchantAccountId(data.data.idMarchandPos);
                setAdyenConfig({
                    ...adyenConfig,
                    currency: currencyExist,
                    merchantAccount: data.data.idMarchandPos,
                });
            }
            if (data.data !== null) {
                setIsShopHasAdyen(true);
                const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/getShopTerminalsList?merchantaccount=${data.data.idMarchandPos}&storename=${data.data.referenceShop}`;
                const responseUrl = await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    method: "GET",
                });

                const dataUrl = await responseUrl.json();

                if (dataUrl !== null) {
                    const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/list-adyen-no-used?shopId=${shopId}&franchiseId=${franchiseID}&adyenIDs=${dataUrl}`;
                    const responseUrlUpdate = await fetch(apiUrlUpdate, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        method: "GET",
                    });

                    const dataNewListe = await responseUrlUpdate.json();
                    setIDAdyen(dataNewListe.difference);
                }
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        if (editedData?.peripheralId !== undefined) {
            setIpExist(
                checkIpIfExist(
                    resourceIp,
                    ipAddress,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId
                )
            );
        } else if (oneShop) {
            setIpExist(checkIpIfExist(resourceIp, ipAddress, "", shopID));
        }
        // eslint-disable-next-line
    }, [editedData, ipAddress]);
    useEffect(() => {
        if (editedData.Id !== undefined) {
            let designation = paymentMethods.find(
                (elt: any) => elt.value === editedData.payment_method.value
            );
            setIsUpdateOperation(true);
            setDesignation(editedData[t("Designation")]);
            setTitle(
                isConsult === true
                    ? t("View peripheral: monetic")
                    : t("Edit device: monetics")
            );
            setReference(editedData[t(`Reference`)]);
            setMark(editedData[t(`Mark`)]);
            setRemarque(editedData[t(`Remark`)]);
            setIdentifier(editedData.Id);
            setNomTpe(editedData.name_tpe);
            setIpAddress(editedData.ip);
            setMerchantCode(editedData.merchant_code);
            setPort(editedData.port);
            setPaymentType(editedData.payment_type);
            setDeviceCode(editedData.device_code);
            setTypeConfig(editedData.setup_type);
            setTypeTerminal(
                editedData.terminal_type.value === "25"
                    ? {
                          label: t("Automate kiosk (Pre-authorization)"),
                          value: "25",
                      }
                    : editedData.terminal_type.value === "22" ||
                      editedData.terminal_type.label === "Autre"
                    ? { label: t("TPE POS (Debit)"), value: "22" }
                    : editedData.terminal_type
            );
            setUrl(editedData.url);
            setSpeed(editedData.speed);
            setPaymentMethod({
                ...editedData.payment_method,
                label: designation !== undefined ? t(designation.label) : "",
            });
            setTimeOut(
                !numberPositiveMatch.test(editedData.timeout)
                    ? "120"
                    : editedData.timeout
            );
            setSelectInputPM(false);
            setInputDesignation(false);
            setSelectMark(false);
            setSpeedSelectInput(false);
            setPortSelectInput(false);
            setInputCode(false);
            setSelectInput(false);
            setInputUrl(false);
            setInputPort(false);
            setInputSetupType(false);
            setInputTerminalType(false);
            setKiss(editedData.kiss);
            setSelectedIdAdyen({
                value: editedData.adyenIp,
                label: editedData.adyenIp,
            });
            setWebhook(editedData.webhook);
            setMode(editedData.mode);
            setInputMode(false);
            setInputWebhook(false);
            setIdIntegrator(editedData.idIntegrator);
            setAutoValidate(editedData.autoValidate);
            setCurrency(editedData.currency);
            setInputCurrency(false);
            setInputIdIntegrator(false);
            setInputTimeout(false);
        } else {
            setIsUpdateOperation(false);
            setDesignation("");
            setTitle(t("Add device: monetics"));
            setReference("");
            setMark("");
            setRemarque("");
            setIdentifier("");
            setNomTpe("");
            setIpAddress("");
            setMerchantCode("");
            setPort("8888");
            setPaymentType("");
            setDeviceCode("");
            setTypeConfig("");
            setTypeTerminal("");
            setSpeed("");
            setTimeOut("120");
            setKiss(true);
            setInputDesignation(false);
            setSelectMark(false);
            setSpeedSelectInput(false);
            setPortSelectInput(false);
            setInputCode(false);
            setSelectInput(false);
            setInputUrl(false);
            setInputSetupType(false);
            setInputTerminalType(false);
            setInputPort(false);
            setSelectInputPM(false);
            setInputTimeout(false);
            // eslint-disable-next-line no-implied-eval
            setTimeout("");
            setInputMode(false);
            setInputWebhook(false);
            setIdIntegrator("");
            setAutoValidate({ label: t("True"), value: t("True") });
            setCurrency("");
            setInputCurrency(false);
            setInputIdIntegrator(false);
            setAdyenConfig(defaultAdyenConfig);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        editedData,
        t,
        isAddNewMoneticsButtonClicked,
        oneShop,
        setIsUpdateOperation,
        isConsult,
        setInputDesignation,
        setSelectMark,
    ]);

    React.useEffect(() => {
        let data: any = [];
        let array = [...tableData.data, ...tableData.othersData.dataArchived];
        if (array !== undefined && array?.length > 0) {
            if (shopIds?.length >= 1 && editedData.Id === undefined) {
                if (array[0][t("Shop")] !== undefined) {
                    shopIds.forEach((shopId: any) => {
                        data = [...data, ...getListMonetics(array, shopId)];
                    });
                } else {
                    data = array;
                }
            } else if (editedData.Id !== undefined) {
                if (array[0][t("Shop")] !== undefined) {
                    data = getListMonetics(array, editedData.shopId);
                } else {
                    data = array;
                }
            }
        }

        let check = checkDesignationIfExist(designation, data);
        if (
            (check === true && editedData.Id === undefined) ||
            (check === true &&
                editedData.Id !== undefined &&
                editedData[t("Designation")].toUpperCase().trim() !==
                    designation.toUpperCase().trim())
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopIds, editedData, designation]);

    React.useEffect(() => {
        if (editedData.ip !== ipAddress) {
            setIpExist(
                checkIpIfExist(
                    resourceIp,
                    ipAddress,
                    editedData?.Id !== undefined ? editedData?.Id : "",
                    editedData?.Id !== undefined
                        ? editedData?.shopId
                        : shopIds.length === 1
                        ? shopIds[0]
                        : ""
                )
            );
        }
        setAdyenIpExist(
            checkIpIfExist(
                resourceIp,
                selectedIdAdyen.value,
                editedData?.Id !== undefined ? editedData?.Id : "",
                editedData?.Id !== undefined
                    ? editedData?.shopId
                    : shopIds.length === 1
                    ? shopIds[0]
                    : ""
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopIds, ipAddress, IDAdyen]);

    useEffect(() => {
        if (editedData.Id !== undefined) {
            setAdyenConfig(editedData.adyenConfig);
        } else {
            setAdyenConfig(defaultAdyenConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, oneShop, selectedRows, tableData, isModalAssociate, t]);

    React.useEffect(() => {
        if (editedData?.Id === undefined) {
            if (mark === "Adyen") setPort("");
            else if (mark === "Valina") setPort("");
            else setPort("8888");
        }
    }, [mark, editedData]);

    React.useEffect(() => {
        if (editedData.Id !== undefined && editedData.Marque === "Adyen") {
            if (
                selectedIdAdyen.value === "" &&
                editedData.adyenIp !== undefined
            ) {
                fetchAdyenInfo(editedData.shopId);
            }
        }
        // eslint-disable-next-line
    }, [editedData]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 mnt_txt_addPaymentDevice">{title}</h5>
                    <CrossIcon
                        className="icon-close-notification__clz mnt_icn_closeForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    resetAllPeripherals();
                                    setEditedData([]);
                                    setIsAddMoneticsClicked!(
                                        !isAddMoneticsClicked
                                    );
                                    setDataIsUpdated!(true);
                                    setIsEdited(false);
                                    setIsConsult(false);
                                });
                            } else {
                                resetAllPeripherals();
                                setEditedData([]);
                                setIsAddMoneticsClicked!(!isAddMoneticsClicked);
                                setDataIsUpdated!(true);
                                setIsEdited(false);
                                setIsConsult(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/monetics"
                                ? "500px"
                                : "522px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3 "
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mnt_inp_name ", {
                                input__clz:
                                    inputDesignation ||
                                    (isNameExist &&
                                        (shopIds === undefined ||
                                            shopIds.length === 0)) ||
                                    (isNameExist && shopIds.length >= 1) ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus={isConsult === true ? false : true}
                        />
                        {(isNameExist &&
                            (shopIds === undefined || shopIds.length === 0)) ||
                        (isNameExist && shopIds.length >= 1) ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                        {inputDesignation ||
                        (designation?.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Monetic type")}
                        </StyledLabel>
                        <React.Fragment>
                            <StyledSelectInput
                                className={classnames("mnt_inp_mark", {
                                    invalid__clz: selectMark,
                                    "not-allowed-input__clz":
                                        editedData.Id !== undefined,
                                    readOnly__clz: isConsult,
                                })}
                                placeholder={t("Select")}
                                onChange={async (e: any) => {
                                    handleOnChangeMark(e);
                                    if (e.value === "Adyen") {
                                        await fetchAdyenInfo(
                                            selectedRows.length > 0
                                                ? selectedRows[0].shopId
                                                : shopIds[0]
                                        );
                                    }
                                }}
                                options={[
                                    {
                                        label: "Nepting",
                                        value: "Nepting",
                                    },
                                    {
                                        label: "Valina",
                                        value: "Valina",
                                    },
                                    {
                                        label: "Adyen",
                                        value: "Adyen",
                                    },
                                    {
                                        label: "Valina swiss",
                                        value: "Valina swiss",
                                    },
                                ]}
                                value={
                                    mark === ""
                                        ? null
                                        : {
                                              label: mark,
                                              value: mark,
                                          }
                                }
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                        </React.Fragment>

                        {selectMark ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mark")}
                            </div>
                        ) : null}
                    </AvGroup>
                    {mark === "Valina" ? (
                        <ValinaConfig
                            port={port}
                            portSelectInput={portSelectInput}
                            isConsult={isConsult}
                            ports={ports}
                            setPortSelectInput={setPortSelectInput}
                            setPort={setPort}
                            setReference={setReference}
                            reference={reference}
                            setIsEdited={setIsEdited}
                            speedSelectInput={speedSelectInput}
                            speed={speed}
                            speeds={speeds}
                            setSpeedSelectInput={setSpeedSelectInput}
                            setSpeed={setSpeed}
                            selectInputPM={selectInputPM}
                            payment_method={payment_method}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            setSelectInputPM={setSelectInputPM}
                            inputTimeout={inputTimeout}
                            setTimeOut={setTimeOut}
                            setInputTimeout={setInputTimeout}
                            timeout={timeout}
                            kiss={kiss}
                            setKiss={setKiss}
                        />
                    ) : mark === "Nepting" ? (
                        <NeptingConfig
                            port={port}
                            isConsult={isConsult}
                            setPort={setPort}
                            setIsEdited={setIsEdited}
                            InputIp={InputIp}
                            ipExist={ipExist}
                            setIpAddress={setIpAddress}
                            ipMatchWord={ipMatchWord}
                            setInputIp={setInputIp}
                            ipAddress={ipAddress}
                            setReference={setReference}
                            reference={reference}
                            InputPort={InputPort}
                            setInputPort={setInputPort}
                            numberMatchWord={numberMatchWord}
                            InputCode={InputCode}
                            setMerchantCode={setMerchantCode}
                            setInputCode={setInputCode}
                            merchantCode={merchantCode}
                            paymentType={paymentType}
                            SelectInput={SelectInput}
                            paramsTypes={paramsTypes}
                            setSelectInput={setSelectInput}
                            setPaymentType={setPaymentType}
                            InputUrl={InputUrl}
                            setUrl={setUrl}
                            setInputUrl={setInputUrl}
                            url={url}
                            inputSetupType={inputSetupType}
                            typeConfig={typeConfig}
                            setTypeConfig={setTypeConfig}
                            typeOfConfig={typeOfConfig}
                            setInputSetupType={setInputSetupType}
                            inputTerminalType={inputTerminalType}
                            typeTerminal={typeTerminal}
                            typeOfTerminal={typeOfTerminal}
                            setTypeTerminal={setTypeTerminal}
                            setInputTerminalType={setInputTerminalType}
                        />
                    ) : mark === "Adyen" ? (
                        <AdyenConfiguration
                            port={port}
                            setPort={setPort}
                            webhook={webhook}
                            setWebhook={setWebhook}
                            inputWebHook={inputWebhook}
                            ipAddress={ipAddress}
                            setIpAddress={setIpAddress}
                            inputIp={InputIp}
                            ipExist={ipExist}
                            mode={mode}
                            setMode={setMode}
                            inputMode={inputMode}
                            setInputWebhook={setInputWebhook}
                            setInputIp={setInputIp}
                            setInputMode={setInputMode}
                            setAdyenIpExist={setAdyenIpExist}
                            setIsEdited={setIsEdited}
                            isConsult={isConsult}
                            setInputPortAdyen={setInputPortAdyen}
                            inputPortAdyen={inputPortAdyen}
                            shopIds={shopIds}
                            editedData={editedData}
                            IDAdyen={IDAdyen}
                            setSelectedIdAdyen={setSelectedIdAdyen}
                            selectedIdAdyen={selectedIdAdyen}
                            isShopHasAdyen={isShopHasAdyen}
                            merchantAccountId={merchantAccountId}
                            currencyByShop={currency}
                            setAdyenConfig={setAdyenConfig}
                            adyenConfig={adyenConfig}
                        />
                    ) : mark === "Valina swiss" ? (
                        <ValinaSwissConfig
                            setIdIntegrator={setIdIntegrator}
                            setInputIdIntegrator={setInputIdIntegrator}
                            setIsEdited={setIsEdited}
                            idIntegrator={idIntegrator}
                            inputIdIntegrator={inputIdIntegrator}
                            isConsult={isConsult}
                            setAutoValidate={setAutoValidate}
                            autoValidate={autoValidate}
                            inputCurrency={inputCurrency}
                            setCurrency={setCurrency}
                            setInputCurrency={setInputCurrency}
                            currency={currency}
                            terminalType={typeTerminal}
                            inputTerminalType={inputTerminalType}
                            setTerminalType={setTypeTerminal}
                            setInputTerminalType={setInputTerminalType}
                        />
                    ) : null}

                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="text">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mnt_inp_remark", {
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            name="remarque"
                            onChange={(e: any) => {
                                setRemarque(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remarque}
                            placeholder={t("Write")}
                            type="text"
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2 ">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mnt_btn_cancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setEditedData([]);
                                setActions(() => {
                                    setIsAddMoneticsClicked(
                                        !isAddMoneticsClicked
                                    );
                                    setDataIsUpdated!(true);
                                    setEditedData([]);
                                    setInputIp(false);
                                    setIsConsult(false);
                                    setIsEdited(false);
                                });
                            } else {
                                setIsConsult(false);
                                setIsAddMoneticsClicked(!isAddMoneticsClicked);
                                setDataIsUpdated!(true);
                                setEditedData([]);
                                setInputIp(false);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={classnames(
                            "mr-2 mnt_btn_validate cmn_btn_validate",
                            {
                                "not-allowed-icon__clz": isConsult,
                            }
                        )}
                        variant="primary"
                        onClick={handleValidate}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddMoneticsClicked}
                setIsAddClicked={setIsAddMoneticsClicked}
                setDataIsUpdated={setDataIsUpdated}
                setEditedData={setEditedData}
            />
        </React.Fragment>
    );
}
