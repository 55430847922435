export const checkFavoriteConditions = (defaultSetting: any, t: any) => {
    let missingFavorites: string[] = [];
    let missingSaleSupport: string[] = [];

    const tvaHasFavoriteWithStatusTrue = JSON.parse(
        JSON.stringify(defaultSetting.tva?.data)
    )?.filter((item: any) => item.status === true);

    const tvaHasFavorite =
        tvaHasFavoriteWithStatusTrue.length !== 0
            ? tvaHasFavoriteWithStatusTrue?.some(
                  (item: any) => item.isFavorite === true
              )
            : true;
    if (!tvaHasFavorite) {
        missingFavorites.push(t("VAT"));
    }

    const languageHasFavoriteWithStatusTrue = JSON.parse(
        JSON.stringify(defaultSetting.language?.data)
    )?.filter((item: any) => item.status === true);
    const languageHasFavorite =
        languageHasFavoriteWithStatusTrue.length !== 0
            ? languageHasFavoriteWithStatusTrue?.some(
                  (item: any) => item.isFavorite === true
              )
            : true;
    if (!languageHasFavorite) {
        missingFavorites.push(t("Language"));
    }

    const saleSupportHasFavoriteWithStatusTrue = JSON.parse(
        JSON.stringify(defaultSetting.saleSupport?.data)
    )?.filter((item: any) => item.status === true);
    const saleSupportHasFavorite =
        saleSupportHasFavoriteWithStatusTrue.length !== 0
            ? saleSupportHasFavoriteWithStatusTrue?.some(
                  (item: any) => item.isFavorite === true
              )
            : true;
    if (!saleSupportHasFavorite) {
        missingFavorites.push(t("Sales support"));
    }

    const saleModeHasFavoriteWithStatusTrue = JSON.parse(
        JSON.stringify(defaultSetting.saleMode?.data)
    )?.filter((item: any) => item.status === true);
    const saleModeHasFavorite =
        saleModeHasFavoriteWithStatusTrue.length !== 0
            ? saleModeHasFavoriteWithStatusTrue?.some(
                  (item: any) => item.isFavorite === true
              )
            : true;
    if (!saleModeHasFavorite) {
        missingFavorites.push(t("Sales method"));
    }

    const saleModeEmpty = defaultSetting.saleMode?.data?.every(
        (item: any) => item.support_vente.length === 0
    );
    if (saleModeEmpty) {
        missingSaleSupport.push(t("Sales method"));
    }

    const paymentMethodEmpty = defaultSetting.paymentMethod?.data?.every(
        (item: any) => item.support_vente.length === 0
    );
    if (paymentMethodEmpty) {
        missingSaleSupport.push(t("Payment method"));
    }

    return {
        missingFavorites,
        missingSaleSupport,
        tvaHasFavoriteWithStatusTrue,
        languageHasFavoriteWithStatusTrue,
        saleSupportHasFavoriteWithStatusTrue,
        saleModeHasFavoriteWithStatusTrue,
        saleModeEmpty,
        paymentMethodEmpty,
        tvaHasFavorite,
        languageHasFavorite,
        saleSupportHasFavorite,
        saleModeHasFavorite,
    };
};
