import React from "react";
import { useTranslation } from "react-i18next";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { useSnapshot } from "valtio";

import { toast } from "react-toastify";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { store } from "@components/VerticalLayout/store";

export function SaveButtonComponent({ setIsOpen, isOpen, setIsLoading }: any) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, shopID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleApplicationValidateButtonClickEvent() {
        setIsOpen(!isOpen);
        setIsLoading(true);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/defaultSetting/shop`;

        let savedData = {
            userId: userID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            franchiseId: franchiseID,
            shopId: shopID,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }

                        toast.success(
                            `${t(
                                "The default configuration has been successfully applied."
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <CustomMainColorButton
            className="w-20 kds_btn_validateTemplate"
            rounded
            variant="primary"
            onClick={() => {
                handleApplicationValidateButtonClickEvent();
            }}
        >
            {t("Save")}
        </CustomMainColorButton>
    );
}
