import React, { Fragment, useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { CustomIcons } from "./CustomIcons";
import { setDefaultSetting, store } from "../store";
import { useSnapshot } from "valtio";
import { DisplayName } from "./DisplayName";

export function Language() {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(store);
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Language"),

            customJsx: (e: any) => (
                <Fragment>{t(e.selectedRow["language"])}</Fragment>
            ),
        },
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
            filterName: "display_name",
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Country"),
            customJsx: (e: any) => (
                <Fragment> {t(e.selectedRow["Pays"])}</Fragment>
            ),
        },
        {
            indexOFColumn: 4,
            columnName: t("Actions"),
            customJsx: (e: any) => <CustomIcons e={e} />,
            disableFilter: false,
        },
    ];
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            language: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Language")}</h2>
            <DynamicTable
                canMovedCheckboxLeftOnExpand
                url={"/DefaultSetting/language.json"}
                setData={setData}
                canSelect
                customSelect
                canResize
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                actionColumn={() => <></>}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                elevationTable={1}
                minHeight={"450px"}
                maxHeight={"450px"}
                name="tva"
            />
        </div>
    );
}
