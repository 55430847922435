import React from "react";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { useTranslation } from "react-i18next";

type DisplayNameType = {
    e: any;
};

export function DisplayName({ e }: DisplayNameType) {
    const { t } = useTranslation();
    const url = e.selectedRow.url_image;
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <CustomColumnDisplay
                    text={
                        <React.Fragment>
                            <img
                                alt="no data"
                                src={url}
                                height="35px"
                                width="40px"
                                className="flag__clz"
                            />{" "}
                            {t(e.selectedRow.display_name)}
                        </React.Fragment>
                    }
                />
            ) : null}
        </React.Fragment>
    );
}
