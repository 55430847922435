import { proxy } from "valtio";

type StoreType = {
    defaultSetting: any;
};

export const store = proxy<StoreType>({
    defaultSetting: {},
});

export function setDefaultSetting(defaultSetting: any): void {
    store.defaultSetting = defaultSetting;
}
export default store;
