import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import { store } from "@store";


import { Tva } from "./Tva";
import { Language } from "./Language";
import { SaleSupport } from "./SaleSupport";
import { SaleMode } from "./SaleMode";
import { PaymentMethod } from "./PaymentMethod";
import { Currency } from "./Currency";
import { Application } from "./Application";
import { Peripheral } from "./Peripheral";

import "./index.css";

type TabsMenuType = {
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};

export function DefaultModalContentWrapper(): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);

    let tabs: TabsMenuType[] = [];

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    tabs = [
        {
            title: t("TVA"),
            content: <Tva />,
            customClassName: "",
        },
        {
            title: t("Language"),
            content: <Language />,
            customClassName: "",
        },
        {
            title: t("Sales support"),
            content: <SaleSupport />,
            customClassName: "",
        },
        {
            title: t("Sales method"),
            content: <SaleMode />,
            customClassName: "",
        },
        {
            title: t("Payment method"),
            content: <PaymentMethod />,
            customClassName: "",
        },
        {
            title: t("Currency"),
            content: <Currency />,
            customClassName: "",
        },
        {
            title: t("Applications"),
            content: <Application />,
            customClassName: "",
        },
        {
            title: t("Peripherals"),
            content: <Peripheral />,
            customClassName: "",
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setActiveSubTab(0);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    React.useEffect(() => {
        setActiveTab(0);
        setActiveSubTab(0);
    }, []);

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem key={index}>
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    },

                                    element.customClassName
                                )}
                                onClick={() => {
                                    toggleNav(index);
                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>

                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",

                                                  {
                                                      "active sub-menu-wrapper-items-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  },

                                                  item.customClassName
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent activeTab={activeTab}>
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane tabId={index} key={index}>
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
