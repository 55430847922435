import React, { Fragment, useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { CustomIcons } from "./CustomIcons";
import { setDefaultSetting, store } from "../store";
import { useSnapshot } from "valtio";

export function Tva() {
    const { t } = useTranslation();

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const { defaultSetting } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("VAT code"),

            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Code TVA"]}</Fragment>
            ),
        },
        {
            indexOFColumn: 4,
            columnName: t("VAT rate"),

            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Taux TVA"]} %</Fragment>
            ),
        },
        {
            indexOFColumn: 997,
            columnName: t("Country"),

            customJsx: (e: any) => (
                <Fragment>{e.selectedRow["Pays"].labels}</Fragment>
            ),
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => <CustomIcons e={e} />,
            disableFilter: false,
        },
    ];
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            tva: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div>
            <h2 className="mt-3 ml-3">{t("VAT")}</h2>
            <DynamicTable
                canMovedCheckboxLeftOnExpand
                url={"/DefaultSetting/tva.json"}
                setData={setData}
                canSelect
                customSelect
                canResize
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                actionColumn={() => <></>}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                elevationTable={1}
                minHeight={"450px"}
                maxHeight={"450px"}
                name="tva"
            />
        </div>
    );
}
