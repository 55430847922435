import React, { useEffect, useState } from "react";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { CustomIcons } from "./CustomIcons";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { setDefaultSetting, store } from "../store";
import { useSnapshot } from "valtio";
import { CustomPaymentMethodIcons } from "./CustomPaymentMethodIcons";

export function PaymentMethod() {
    const { t } = useTranslation();

    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean | number>(false);
    const [data, setData] = useState<any>({});
    const { defaultSetting } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Payment method"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={t(e.selectedRow["Type of payment method"])}
                    />
                );
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Display name"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={t(e.selectedRow["Display name"])}
                    />
                );
            },
        },

        {
            indexOFColumn: 2,
            columnName: t("Sales support"),
            customJsx: (e: any) => <CustomPaymentMethodIcons e={e} />,
        },
        {
            indexOFColumn: 3,
            columnName: t("Actions"),
            customJsx: (e: any) => <CustomIcons e={e} />,
        },
    ];
    useEffect(() => {
        setDefaultSetting({
            ...defaultSetting,
            paymentMethod: JSON.parse(JSON.stringify({ data: data.data })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        <div>
            <h2 className="mt-3 ml-3">{t("Payment method")}</h2>
            <DynamicTable
                canMovedCheckboxLeftOnExpand
                url={"/DefaultSetting/paymentMethod.json"}
                setData={setData}
                canSelect
                customSelect
                canResize
                showGlobalFilter
                showFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                actionColumn={() => <></>}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                elevationTable={1}
                minHeight={"450px"}
                maxHeight={"450px"}
                name="tva"
            />
        </div>
    );
}
