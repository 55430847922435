import React from "react";

import { useSnapshot } from "valtio";
import { store } from "../store";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

export function CustomPaymentMethodIcons({ e }: any): JSX.Element {
    const { defaultSetting } = useSnapshot(store);

    const matchingItem = defaultSetting.paymentMethod.data.find(
        (item: any) => item.Id === e.selectedRow.Id
    );

    const supportVente = matchingItem?.support_vente?.join(", ") || "";
    return (
        <div className="d-flex justify-content-around">
            <CustomColumnDisplay text={supportVente} />
        </div>
    );
}
