import React from "react";
import { Input } from "reactstrap";
import { useSnapshot } from "valtio";
import { setDefaultSetting, store } from "../store";

import { useTranslation } from "react-i18next";

export function CustomIcons({ e }: any): JSX.Element {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(store);
    const handleInputChange = (input: boolean) => {
        const updatedRows = defaultSetting.applications?.data?.map(
            (row: any) => {
                if (row.Id === e.selectedRow.Id) {
                    return { ...row, number: Number(input) };
                }
                return row;
            }
        );
        setDefaultSetting({
            ...defaultSetting,
            applications: JSON.parse(JSON.stringify({ data: updatedRows })),
        });
    };

    const selectedRow = defaultSetting.applications?.data?.find(
        (row: any) => row.Id === e.selectedRow.Id
    );

    return (
        <div className="d-flex justify-content-around">
            <Input
                name="value"
                placeholder={t("Write")}
                type="number"
                step="0.00"
                min="0"
                value={selectedRow?.number || 0}
                autocomplete="off"
                onChange={(e: any) => {
                    handleInputChange(e.target.value);
                }}
            />
        </div>
    );
}
