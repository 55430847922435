import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { Button } from "reactstrap";
import { CheckIcon } from "@aureskonnect/react-ui";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import {
    setCheckboxIcon,
    setCheckboxIconSecondModel,
    setCheckboxIconThreedModel,
    setCheckboxIconFourthModel,
    setCheckboxIconFifthModel,
    store,
    setDataFirstTicket,
    setDataSecondTicket,
    setDatathreedTicket,
    setDataFourthTicket,
    setDataFifthTicket,
} from "../store";
import {
    setFirstModelBlackBackground,
    setFirstModelSmallerFont,
    storeModelTicket,
} from "./store";

import FirstModel from "./FirstModel";
import SecondModel from "./SecondModel";
import ThreedModel from "./ThreedModel";
import FourthModel from "./FourthModel";
import FifthModel from "./FifthModel";

import "./index.css";
import "../index.css";

type ModelTicketType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

export default function ModelTicket({
    rowData,
    oneShop,
    setDataIsUpdated,
    isDataUpdated,
}: ModelTicketType) {
    const { t } = useTranslation();
    const [model, setModel] = useState<number>(1);
    const {
        checkboxIcon,
        checkboxIconSecondModel,
        checkboxIconThreedModel,
        checkboxIconFourthModel,
        checkboxIconFifthModel,
    } = useSnapshot(store);
    const { firstModelBlackBackground, firstModelSmallerFont } = useSnapshot(
        storeModelTicket
    );
    const {
        threedModelFollowKitchenTicket,
        threedModelFontSmall,
        threedModelDispatchAutomaticallyFollow,
        threedModelActivateManualDispatchFollow,
        threedModelOrderKitchen,
        threedModelSendChangesKitchen,
        threedModelTicketAdditionsCancellations,
    } = useSnapshot(storeModelTicket);
    const {
        secondModelSingleLabel,
        secondModelOnlineOrder,
        secondModelMenuComponent,
        secondModelNameMenu,
    } = useSnapshot(storeModelTicket);

    useEffect(() => {
        setCheckboxIconThreedModel(
            rowData?.modelKitchenTicket?.model_3?.isChecked
                ? rowData?.modelKitchenTicket?.model_3.isChecked
                : false
        );
        setCheckboxIcon(
            rowData?.modelKitchenTicket?.model_1?.isChecked
                ? rowData?.modelKitchenTicket?.model_1.isChecked
                : true
        );
        setCheckboxIconSecondModel(
            rowData?.modelKitchenTicket?.model_2?.isChecked
                ? rowData?.modelKitchenTicket.model_2.isChecked
                : false
        );
        setCheckboxIconFourthModel(
            rowData?.modelKitchenTicket?.model_4?.isChecked
                ? rowData?.modelKitchenTicket.model_4.isChecked
                : false
        );
        setCheckboxIconFifthModel(
            rowData?.modelKitchenTicket?.model_5?.isChecked
                ? rowData?.modelKitchenTicket.model_5.isChecked
                : false
        );
        setFirstModelSmallerFont(
            rowData?.modelKitchenTicket?.model_1?.smaller_font
                ? rowData?.modelKitchenTicket?.model_1.smaller_font
                : true
        );
        setFirstModelBlackBackground(
            rowData?.modelKitchenTicket?.model_1?.black_background
                ? rowData?.modelKitchenTicket?.model_1.black_background
                : true
        );
    }, [rowData, rowData?.modelKitchenTicket]);

    const listBtnModels = [
        { 1: checkboxIcon },
        { 2: checkboxIconSecondModel },
        { 3: checkboxIconThreedModel },
        { 4: checkboxIconFourthModel },
        { 5: checkboxIconFifthModel },
    ];

    React.useEffect(() => {
        rowData?.modelKitchenTicket &&
            Object.keys(rowData?.modelKitchenTicket).forEach((elm: any) => {
                if (rowData?.modelKitchenTicket[elm].isChecked) {
                    const test = elm.at(-1);
                    setModel(+test);
                }
            });
        if (rowData?.modelKitchenTicket?.model_2?.isChecked) {
            setCheckboxIcon(false);
        } else if (rowData?.modelKitchenTicket?.model_2?.isChecked) {
            setCheckboxIconSecondModel(false);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const data: any = {
            black_background: firstModelBlackBackground,
            smaller_font: firstModelSmallerFont,
            isChecked: checkboxIcon,
        };

        setDataFirstTicket(data);
    }, [firstModelBlackBackground, firstModelSmallerFont, checkboxIcon]);
    useEffect(() => {
        const data: any = {
            single_label: secondModelSingleLabel,
            online_order: secondModelOnlineOrder,
            menu_component: secondModelMenuComponent,
            name_menu: secondModelNameMenu,
            isChecked: checkboxIconSecondModel,
        };
        setDataSecondTicket(data);
    }, [
        secondModelSingleLabel,
        secondModelOnlineOrder,
        secondModelMenuComponent,
        secondModelNameMenu,
        checkboxIconSecondModel,
    ]);
    useEffect(() => {
        const data: any = {
            follow_kitchen_ticket: threedModelFollowKitchenTicket,
            font_small: threedModelFontSmall,
            dispatch_automatically_follow: threedModelDispatchAutomaticallyFollow,
            Activate_manual_dispatch_follow: threedModelActivateManualDispatchFollow,
            order_kitchen: threedModelOrderKitchen,
            Send_changes_kitchen: threedModelSendChangesKitchen,
            ticket_additions_cancellations: threedModelTicketAdditionsCancellations,
            isChecked: checkboxIconThreedModel,
        };

        setDatathreedTicket(data);
    }, [
        threedModelFollowKitchenTicket,
        threedModelFontSmall,
        threedModelDispatchAutomaticallyFollow,
        threedModelActivateManualDispatchFollow,
        threedModelOrderKitchen,
        threedModelSendChangesKitchen,
        threedModelTicketAdditionsCancellations,
        checkboxIconThreedModel,
    ]);
    useEffect(() => {
        const data: any = {
            isChecked: checkboxIconFourthModel,
        };
        setDataFourthTicket(data);
    }, [checkboxIconFourthModel]);
    useEffect(() => {
        const data: any = {
            isChecked: checkboxIconFifthModel,
        };
        setDataFifthTicket(data);
    }, [checkboxIconFifthModel]);

    const setCheckedModel = (model: number) => {
        switch (model) {
            case 1:
                setCheckboxIcon(!checkboxIcon);
                setCheckboxIconSecondModel(false);
                setCheckboxIconThreedModel(false);
                setCheckboxIconFourthModel(false);
                setCheckboxIconFifthModel(false);
                break;
            case 2:
                setCheckboxIcon(false);
                setCheckboxIconSecondModel(!checkboxIconSecondModel);
                setCheckboxIconThreedModel(false);
                setCheckboxIconFourthModel(false);
                setCheckboxIconFifthModel(false);
                break;
            case 3:
                setCheckboxIcon(false);
                setCheckboxIconSecondModel(false);
                setCheckboxIconThreedModel(!checkboxIconThreedModel);
                setCheckboxIconFourthModel(false);
                setCheckboxIconFifthModel(false);
                break;
            case 4:
                setCheckboxIcon(false);
                setCheckboxIconSecondModel(false);
                setCheckboxIconThreedModel(false);
                setCheckboxIconFourthModel(!checkboxIconFourthModel);
                setCheckboxIconFifthModel(false);
                break;
            case 5:
                setCheckboxIcon(false);
                setCheckboxIconSecondModel(false);
                setCheckboxIconThreedModel(false);
                setCheckboxIconFourthModel(false);
                setCheckboxIconFifthModel(!checkboxIconFifthModel);
                break;
            default:
        }
    };
    const renderCurrentSelection = () => {
        switch (+model) {
            case 1:
                return (
                    <FirstModel
                        rowData={rowData}
                        oneShop={oneShop}
                        setCheckboxIcon={() => setCheckedModel(+model)}
                        setDataIsUpdated={setDataIsUpdated}
                        isDataUpdated={isDataUpdated}
                    />
                );
            case 2:
                return (
                    <SecondModel
                        rowData={rowData}
                        oneShop={oneShop}
                        setCheckboxIconSecondModel={() =>
                            setCheckedModel(+model)
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        isDataUpdated={isDataUpdated}
                    />
                );
            case 3:
                return (
                    <ThreedModel
                        rowData={rowData}
                        oneShop={oneShop}
                        setCheckboxIconThreedModel={() =>
                            setCheckedModel(+model)
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        isDataUpdated={isDataUpdated}
                    />
                );
            case 4:
                return (
                    <FourthModel
                        rowData={rowData}
                        oneShop={oneShop}
                        setCheckboxIconFourthModel={() =>
                            setCheckedModel(+model)
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        isDataUpdated={isDataUpdated}
                    />
                );
            case 5:
                return (
                    <FifthModel
                        rowData={rowData}
                        oneShop={oneShop}
                        setCheckboxIconFifthModel={() =>
                            setCheckedModel(+model)
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        isDataUpdated={isDataUpdated}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <div style={{ height: "811px" }}>
            <div className="px-3 pt-2">
                <h4 style={{ fontSize: "24px" }}>
                    {t("Models of kitchen tickets")}
                </h4>

                <h4 style={{ opacity: "0.3" }}>
                    {t("Select and configure one among the proposed models")}
                </h4>
            </div>
            <div className="style-card-button-model">
                <div className="d-flex d-flex align-items-center ml-3">
                    {listBtnModels.map((elm: any, index) => {
                        return (
                            <Button
                                outline
                                onClick={() => setModel(index + 1)}
                                className={classnames(
                                    "ml-2 mt-2 style-btn-model__clz",
                                    {
                                        " btn-model__clz": model === index + 1,
                                        "not-allowed-icon__clz":
                                            Object.keys(elm)[0] !== "1" &&
                                            Object.keys(elm)[0] !== "2",
                                    },
                                    `afa_btn_model${Object.keys(elm)}`
                                )}
                                style={{
                                    backgroundColor:
                                        model === index + 1 ? "black" : "",
                                }}
                            >
                                <span
                                    className="style-model__clz"
                                    style={{
                                        color:
                                            model === index + 1
                                                ? "#ffffff"
                                                : "",
                                        fontSize: "15px",
                                    }}
                                >
                                    {t(`Model ${index + 1}`)}
                                </span>

                                <CheckIcon
                                    height={25}
                                    width={25}
                                    fill={
                                        model === index + 1 ? "white" : "black"
                                    }
                                    className={classnames("ml-4", {
                                        "d-none":
                                            (Object.keys(elm)[0] !== "1" &&
                                                checkboxIcon) ||
                                            (Object.keys(elm)[0] !== "2" &&
                                                checkboxIconSecondModel),
                                    })}
                                />
                            </Button>
                        );
                    })}
                </div>
            </div>
            <div className="card-modal-component__clz">
                {renderCurrentSelection()}
            </div>
        </div>
    );
}
