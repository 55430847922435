import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { HeartIcon, PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { setDefaultSetting, store } from "../store";

export function CustomIcons({ e }: any): JSX.Element {
    const { t } = useTranslation();

    const [isActive, setIsActive] = useState(e.selectedRow.status);
    const { defaultSetting } = useSnapshot(store);

    const handleHeartClick = () => {
        const updatedRows = defaultSetting.tva?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, isFavorite: true };
            }
            return { ...row, isFavorite: false };
        });

        setDefaultSetting({
            ...defaultSetting,
            tva: JSON.parse(JSON.stringify({ data: updatedRows })),
        });
    };

    const handlePencilClick = () => {};

    const handleSwitchChange = (checked: boolean) => {
        const updatedRows = defaultSetting.tva?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, status: checked };
            }
            return row;
        });
        setDefaultSetting({
            ...defaultSetting,
            tva: JSON.parse(JSON.stringify({ data: updatedRows })),
        });

        setIsActive(checked);
    };

    const isFavoriteItems =
        defaultSetting.tva?.data?.filter(
            (item: any) => item.isFavorite === true
        ) || [];

    const isFavorite =
        isFavoriteItems.length > 0 &&
        isFavoriteItems[0].Id === e.selectedRow.Id;
    return (
        <div className="d-flex justify-content-around">
            <Tooltip
                title={isActive === false ? t("Activate") : t("Unactivate")}
                arrow
            >
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={isActive}
                        onChange={handleSwitchChange}
                    />
                </div>
            </Tooltip>
            <div className="ml-3">
                <Tooltip
                    title={
                        e.selectedRow.isFavorite === true
                            ? ""
                            : t("Enable favorite state")
                    }
                    arrow
                >
                    <div>
                        <HeartIcon
                            onClick={handleHeartClick}
                            height={25}
                            width={25}
                            name="heart"
                            fill={isFavorite ? "red" : "black"}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="ml-3">
                <Tooltip title={t("To modify")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
