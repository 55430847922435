import React from "react";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { setDefaultSetting, store } from "../store";

export function TypeIcons({ e }: any): JSX.Element {
    const { t } = useTranslation();
    const { defaultSetting } = useSnapshot(store);
    const handleInputChange = (input: boolean) => {
        const updatedRows = defaultSetting.peripheral?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, Type: input };
            }
            return row;
        });
        setDefaultSetting({
            ...defaultSetting,
            peripheral: JSON.parse(JSON.stringify({ data: updatedRows })),
        });
    };

    const selectedRow = defaultSetting.peripheral?.data?.find(
        (row: any) => row.Id === e.selectedRow.Id
    );
    return (
        <AvForm style={{ width: "140px" }}>
            <AvGroup>
                <StyledSelectInput
                    options={e.selectedRow.typeOptions}
                    autoComplete="off"
                    placeholder={`${t("Select")}…`}
                    value={{
                        label: selectedRow.Type,
                        value: selectedRow.Type,
                    }}
                    onChange={(e: any) => {
                        handleInputChange(e.value);
                    }}
                    noOptionsMessage={() => t("No options")}
                    name={t("Sales support")}
                    autoFocus
                />
            </AvGroup>
        </AvForm>
    );
}
