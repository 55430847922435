import React, { useEffect } from "react";
import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import Switch from "react-switch";

import { store as userStore } from "@components/VerticalLayout/store";
import { setMoneticData, store } from "./store";

import { ipMatchWord, numberPositiveMatch } from "@constants/index";
import { SPEEDS } from "../../../../constants";
import { checkIpIfExist } from "@helpers/general";
import { AdyenConfiguration } from "./AdyenConfig";
import { ValinaSwissConfig } from "./ValinaSwissConfig";
import { NeptingConfig } from "./NeptingConfig";

type MoneticsType = {
    editedData: any;
    setIsEdited: Function;
    selectedRows: any;
    isConsult: any;
    tableData: any;
    shopIds: any;
};

export function MoneticPeripheralConfigure({
    editedData,
    setIsEdited,
    selectedRows,
    isConsult,
    tableData,
    shopIds,
}: MoneticsType) {
    const { t } = useTranslation();
    const { shopID, oneShop, resourceIp } = useSnapshot(userStore);
    const {
        designation,
        mark,
        note,
        reference,
        port,
        selectMark,
        speed,
        ipAddress,
        inputDesignation,
        isNameExist,
        portSelectInput,
        speedSelectInput,
        kiss,
        timeout,
        inputTimeout,
        paymentMethod,
        inputPaymentMethod,
        adyenIpAddress,
    } = useSnapshot(store);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const valinaReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const payment_method: {
        label: string;
        value: string;
    }[] = [
        { label: t("By default"), value: "1" },

        { label: t("payment disabled"), value: "4" },
        { label: t("payment enabled"), value: "3" },
    ];
    const paymentMethods: {
        label: string;
        value: string;
    }[] = [
        { label: t("By default"), value: "1" },

        { label: t("payment disabled"), value: "4" },
        { label: t("payment enabled"), value: "3" },
    ];
    const ports: LocalOptionsType[] = Array.from(
        { length: 256 },
        (_, i) => "COM" + (i + 1)
    ).map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const speeds: LocalOptionsType[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });
    const shopId = oneShop ? shopID : selectedRows?.shopId;

    const [adyenConfig, setAdyenConfig] = React.useState<any>({});

    React.useEffect(() => {
        if (timeout?.trim() === "") {
            setMoneticData(false, "inputTimeout");
        }
        if (!numberPositiveMatch.test(timeout) && mark === "Valina") {
            setMoneticData(true, "inputTimeout");
        }
    }, [mark, timeout]);

    useEffect(() => {
        if (editedData.length !== 0) {
            let designation = paymentMethods.find(
                (elt: any) => elt?.value === editedData.payment_method?.value
            );
            setMoneticData(editedData[t("Peripheral")], "designation");
            setMoneticData(editedData.reference, "reference");
            setMoneticData(editedData.mark, "mark");
            setMoneticData(editedData.note, "note");
            setMoneticData(editedData.peripheralId, "identifiant");
            setMoneticData(editedData.tpe_name, "nomTpe");
            setMoneticData(editedData.ip, "ipAddress");
            setMoneticData(editedData.merchant_code, "merchantCode");
            setMoneticData(editedData.port, "port");
            setMoneticData(editedData.payment_type, "paymentType");
            setMoneticData(editedData.device_code, "deviceCode");
            setMoneticData(editedData.setup_type, "typeConfig");
            setMoneticData(false, "inputTimeout");
            setMoneticData(
                editedData.terminal_type !== undefined
                    ? editedData.terminal_type.value === "25"
                        ? {
                              label: t("Automate kiosk (Pre-authorization)"),
                              value: "25",
                          }
                        : editedData.terminal_type.value === "22" ||
                          editedData.terminal_type.label === "Autre"
                        ? { label: "TPE POS (Debit)", value: "22" }
                        : editedData.terminal_type
                    : "",
                "typeTerminal"
            );
            setMoneticData(
                editedData.url === "" ? editedData.path : editedData.url,
                "url"
            );
            setMoneticData(editedData.speed, "speed");
            setMoneticData(
                {
                    ...editedData.payment_method,
                    label:
                        designation !== undefined ? t(designation.label) : "",
                },
                "paymentMethod"
            );
            setMoneticData(editedData.time_out, "timeout");
            setMoneticData(editedData.adyenIp, "adyenIpAddress");
            setMoneticData(editedData.mode, "mode");
            setMoneticData(editedData.webhook, "webhook");
            setMoneticData(editedData.idIntegrator, "idIntegrator");
            setMoneticData(editedData.autoValidate, "autoValidate");
            setMoneticData(editedData.currency, "currency");
            setMoneticData(false, "selectMark");
            setMoneticData(false, "inputDesignation");
            setMoneticData(false, "inputSetupType");
            setMoneticData(false, "inputTerminalType");
            setMoneticData(false, "inputAdyenIpAddress");
            setMoneticData(false, "inputMode");
            setMoneticData(false, "isConsult");
            setMoneticData(false, "inputWebHook");
            setMoneticData(false, "inputIdIntegrator");
            setMoneticData(false, "inputAutoValidate");
            setMoneticData(false, "inputCurrency");
            setMoneticData(false, "InputCode");
            setMoneticData(false, "SelectInput");
            setMoneticData(false, "InputUrl");
        } else {
            setMoneticData("", "designation");
            setMoneticData("", "reference");
            setMoneticData("", "mark");
            setMoneticData("", "note");
            setMoneticData("", "identifiant");
            setMoneticData("", "nomTpe");
            setMoneticData("", "ipadress");
            setMoneticData("", "merchantcode");
            setMoneticData("", "port");
            setMoneticData("", "paymentType");
            setMoneticData("", "deviceCode");
            setMoneticData("", "typeConfig");
            setMoneticData("", "typeTerminal");
            setMoneticData("", "timeout");
            setMoneticData("", "adyenIpAddress");
            setMoneticData("Production", "mode");
            setMoneticData("", "webhook");
            setMoneticData("", "idIntegrator");
            setMoneticData("", "autoValidate");
            setMoneticData("", "currency");
            setMoneticData(false, "adyenIpExist");
            setMoneticData(
                {
                    label: t("payment disabled"),
                    value: "3",
                },
                "paymentMethod"
            );
        }
        setMoneticData(
            shopIds.length <= 1
                ? oneShop && tableData !== undefined
                    ? tableData.othersData.adyenConfig
                    : selectedRows !== undefined
                    ? selectedRows.adyenConfig
                    : {}
                : {},
            "adyenConfig"
        );
        setMoneticData(false, "selectMark");
        setMoneticData(false, "inputDesignation");
        setMoneticData(false, "inputSetupType");
        setMoneticData(false, "inputTerminalType");
        setMoneticData(false, "inputAdyenIpAddress");
        setMoneticData(false, "inputMode");
        setMoneticData(false, "isConsult");
        setMoneticData(false, "inputWebHook");
        setMoneticData(false, "inputIdIntegrator");
        setMoneticData(false, "inputAutoValidate");
        setMoneticData(false, "inputCurrency");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData]);

    React.useEffect(() => {
        setMoneticData(
            (shopIds.length > 1 || shopIds.length === 0) &&
                editedData?.shopId !== undefined
                ? false
                : checkIpIfExist(
                      resourceIp,
                      ipAddress,
                      editedData?.peripheralId !== undefined
                          ? editedData?.peripheralId
                          : "",
                      editedData?.peripheralId !== undefined
                          ? editedData?.shopId
                          : shopId
                  ),
            "ipExist"
        );
        setMoneticData(
            shopIds.length > 1 || shopIds.length === 0
                ? false
                : checkIpIfExist(
                      resourceIp,
                      adyenIpAddress,
                      editedData?.peripheralId !== undefined
                          ? editedData?.peripheralId
                          : "",
                      editedData?.peripheralId !== undefined
                          ? editedData?.shopId
                          : shopId
                  ),
            "adyenIpExist"
        );
        if (ipAddress !== "") {
            setMoneticData(false, "emptyIp");
            setMoneticData(!ipAddress.match(ipMatchWord), "inputIp");
        }
        if (ipAddress === "") {
            setMoneticData(false, "ipExist");
            setMoneticData(false, "inputIp");
            setMoneticData(false, "emptyIp");
        }
        if (adyenIpAddress === "") {
            setMoneticData(false, "adyenIpExist");
            setMoneticData(false, "inputAdyenIpAddress");
        }

        if (editedData[t("IP address")] !== undefined) {
            let filteredData = JSON.parse(JSON.stringify(resourceIp))
                ?.filter(
                    (item: any) =>
                        item?.shopId === shopId &&
                        item.ip.trim() !== "" &&
                        item.ip.trim() !== editedData[t("IP address")] &&
                        item.ip.trim() !== editedData.ip
                )
                .map((y: any) => {
                    return y.ip;
                });
            filteredData = [...new Set(filteredData)];
            if (filteredData.includes(ipAddress)) {
                setMoneticData(true, "ipExist");
            }
        }
        if (
            editedData[t("IP address")] !== undefined &&
            editedData[t("Mark")] === "Adyen"
        ) {
            let filteredData = JSON.parse(JSON.stringify(resourceIp))
                ?.filter(
                    (item: any) =>
                        item?.shopId === shopId &&
                        item.ip.trim() !== "" &&
                        item.ip.trim() !== editedData.ip &&
                        item.ip.trim() !== editedData.adyenIp
                )
                .map((y: any) => {
                    return y.ip;
                });

            filteredData = [...new Set(filteredData)];
            if (filteredData.includes(adyenIpAddress)) {
                setMoneticData(true, "adyenIpExist");
            }
        }

        if (editedData?.peripheralId !== undefined) {
            setMoneticData(
                checkIpIfExist(
                    resourceIp,
                    ipAddress,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId
                ),
                "ipExist"
            );
            setMoneticData(
                checkIpIfExist(
                    resourceIp,
                    adyenIpAddress,
                    editedData?.peripheralId,
                    oneShop ? shopID : editedData?.shopId
                ),
                "adyenIpExist"
            );
        } else if (oneShop) {
            setMoneticData(
                checkIpIfExist(resourceIp, ipAddress, "", shopID),
                "ipExist"
            );
            setMoneticData(
                checkIpIfExist(resourceIp, adyenIpAddress, "", shopID),
                "adyenIpExist"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, ipAddress, adyenIpAddress]);

    React.useEffect(() => {
        let result: number = resourceIp.filter((el: any) => {
            if (editedData?.peripheralId !== undefined) {
                return (
                    el.shopId === shopId &&
                    el.type === "monetics" &&
                    el.id !== editedData?.peripheralId &&
                    el.designation.toUpperCase().trim() ===
                        designation.toUpperCase().trim()
                );
            } else {
                return (
                    el.shopId === shopId &&
                    el.type === "monetics" &&
                    el.designation.toUpperCase().trim() ===
                        designation.toUpperCase().trim()
                );
            }
        }).length;

        setMoneticData(result > 0, "isNameExist");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, designation]);
    useEffect(() => {
        if (editedData?.peripheralId !== undefined) {
            setAdyenConfig(editedData.adyenConfig);
        } else {
            setAdyenConfig({});
        }
    }, [editedData, oneShop, selectedRows, tableData, t, shopIds]);

    useEffect(() => {
        setMoneticData(adyenConfig, "adyenConfig");
    }, [adyenConfig]);

    return (
        <React.Fragment>
            <AvGroup
                className={`  ${isConsult === true ? "pe-none__clz" : ""}`}
            >
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Designation")}
                </StyledLabel>
                <StyledTextInput
                    className={classnames("afp_inp_deviceName", {
                        input__clz:
                            inputDesignation ||
                            isNameExist ||
                            (designation?.trim() === "" && designation !== ""),
                    })}
                    autocomplete="off"
                    name="designation"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "designation");
                        setIsEdited(true);
                        setMoneticData(false, "inputDesignation");
                    }}
                    value={designation}
                    placeholder={t("Write")}
                    type="text"
                    validate={{
                        pattern: {
                            value: notStartWithSpaceRegExp,
                            errorMessage: t("Designation is invalid"),
                        },
                    }}
                />
                {isNameExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The name is already exists")!}
                    </div>
                ) : null}
                {inputDesignation ||
                (designation?.trim() === "" && designation !== "") ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a designation")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Monetic type")}
                </StyledLabel>
                <React.Fragment>
                    <StyledSelectInput
                        className={classnames("afp_drp_mark", {
                            invalid__clz: selectMark,
                            "not-allowed-input__clz":
                                editedData?.peripheralId !== undefined,
                        })}
                        placeholder={t("Select")}
                        onChange={(e: any) => {
                            setMoneticData(false, "selectMark");
                            setMoneticData(false, "portSelectInput");
                            setMoneticData(false, "speedSelectInput");
                            setMoneticData(false, "inputUrl");
                            setMoneticData(false, "selectInput");
                            setMoneticData(false, "inputCode");
                            setMoneticData(false, "inputPort");
                            setMoneticData(false, "inputIp");
                            setMoneticData(false, "inputSetupType");
                            setMoneticData(false, "inputTerminalType");
                            setMoneticData(e.value, "mark");
                            setIsEdited(true);
                            setMoneticData(false, "inputDesignation");
                            if (e.value === "Valina") {
                                setMoneticData("EXAMPLE 1", "reference");
                                setMoneticData("", "port");
                            } else if (e.value === "Nepting") {
                                setMoneticData("S300", "reference");
                                setMoneticData("8000", "port");
                            } else {
                                setMoneticData("", "reference");
                                setMoneticData("", "port");
                            }
                            setMoneticData("", "ipAddress");
                        }}
                        options={[
                            {
                                label: "Nepting",
                                value: "Nepting",
                            },
                            {
                                label: "Valina",
                                value: "Valina",
                            },
                            {
                                label: "Adyen",
                                value: "Adyen",
                            },
                            {
                                label: "Valina swiss",
                                value: "Valina swiss",
                            },
                        ]}
                        value={
                            mark === ""
                                ? null
                                : {
                                      label: mark,
                                      value: mark,
                                  }
                        }
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                    />
                </React.Fragment>

                {selectMark ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a mark")}
                    </div>
                ) : null}
            </AvGroup>
            {mark === "Valina" ? (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Reference")}
                        </StyledLabel>

                        <StyledSelectInput
                            onChange={(e: any) => {
                                setMoneticData(e.value, "reference");
                                setIsEdited(true);
                            }}
                            value={
                                reference === ""
                                    ? null
                                    : {
                                          label: reference,
                                          value: reference,
                                      }
                            }
                            options={valinaReferencesArray}
                            name="reference"
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                            className="afp_drp_reference"
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Port number")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames("afp_inp_portNumber", {
                                invalid__clz: portSelectInput,
                            })}
                            value={
                                port === ""
                                    ? null
                                    : {
                                          label: port,
                                          value: port,
                                      }
                            }
                            id="port"
                            name="port"
                            options={ports}
                            onChange={(e: any) => {
                                setMoneticData(false, "portSelectInput");
                                setMoneticData(e.label, "port");
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />

                        {portSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a port number")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Speed")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames("afp_inp_speed", {
                                invalid__clz: speedSelectInput,
                            })}
                            value={
                                speed === ""
                                    ? null
                                    : {
                                          label: speed,
                                          value: speed,
                                      }
                            }
                            id="speed"
                            name="speed"
                            options={speeds}
                            onChange={(e: any) => {
                                setMoneticData(false, "speedSelectInput");
                                setMoneticData(e.label, "speed");
                                setMoneticData(true, "isEdited");
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />

                        {speedSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a speed")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Method of payment")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames(
                                "mnt_inp_speed afp_drp_methodOfPayment",
                                {
                                    invalid__clz: inputPaymentMethod,
                                }
                            )}
                            value={
                                paymentMethod === ""
                                    ? {
                                          label: t("payment disabled"),
                                          value: "3",
                                      }
                                    : paymentMethod
                            }
                            id="Method_of_payment"
                            name="Method_of_payment"
                            options={payment_method}
                            onChange={(e: any) => {
                                setMoneticData(false, "paymentMethod");
                                setMoneticData(e, "paymentMethod");
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="80px"
                        />

                        {inputPaymentMethod && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a method of payment")}
                            </div>
                        )}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Timeout")}
                            {` (s)`}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames(
                                "mnt_inp_ipAdress afp_inp_break",
                                {
                                    input__clz: inputTimeout,
                                }
                            )}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Write")}
                            type="text"
                            min={0}
                            onChange={(e: any) => {
                                let val = e.target.value;
                                setMoneticData(val, "timeout");
                                if (
                                    !numberPositiveMatch.test(val) ||
                                    val.trim() === ""
                                ) {
                                    setMoneticData(true, "inputTimeout");
                                } else {
                                    setMoneticData(false, "inputTimeout");
                                }
                                setIsEdited(true);
                            }}
                            validate={{
                                pattern: {
                                    value: /.*$/,
                                    errorMessage: t("Time out is invalid"),
                                },
                            }}
                            value={timeout}
                        />
                        {inputTimeout && timeout?.trim() === "" ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter the timeout")!}
                            </div>
                        ) : inputTimeout ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Timeout is invalid")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="text">
                            {t("Activate the protocol KISS")}
                        </StyledLabel>
                        <div className="float-right afp_icn_kiss">
                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                className="mt-3  pl-2"
                                onHandleColor="#34C38F"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={kiss}
                                height={20}
                                handleDiameter={24}
                                name="activate-protocol-kiss"
                                onChange={(e: any) => {
                                    setMoneticData(!kiss, "kiss");
                                    setIsEdited(true);
                                }}
                            />
                        </div>
                    </AvGroup>
                </React.Fragment>
            ) : mark === "Nepting" ? (
                <NeptingConfig setIsEdited={setIsEdited} />
            ) : mark === "Adyen" ? (
                <AdyenConfiguration
                    setIsEdited={setIsEdited}
                    adyenConfig={adyenConfig}
                    setAdyenConfig={setAdyenConfig}
                    shopIds={shopIds}
                    editedData={editedData}
                />
            ) : mark === "Valina swiss" ? (
                <ValinaSwissConfig setIsEdited={setIsEdited} />
            ) : null}
            <AvGroup>
                <StyledLabel className="mt-3" htmlFor="text">
                    {t("Remark")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    name="remarque"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "note");
                        setIsEdited(true);
                    }}
                    value={note}
                    placeholder={t("Write")}
                    type="text"
                    className="afp_inp_remark"
                />
            </AvGroup>
        </React.Fragment>
    );
}
