import React from "react";
import { useTranslation } from "react-i18next";

import { useSnapshot } from "valtio";
import { store as defaultSettingStore } from "./DefaultModalContentWrapper/store";
import {
    filterActiveItems,
    generateClassAndText,
    generateItemText,
    generateSpecificItemText,
    generateTvaText,
} from "@helpers/general";

export function ConfigElement() {
    const { t } = useTranslation();

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const { defaultSetting } = useSnapshot(defaultSettingStore);

    const filteredTva = filterActiveItems(defaultSetting.tva?.data, "status");
    const filteredSaleMode = filterActiveItems(
        defaultSetting.saleMode?.data,
        "status"
    );
    const filteredLanguage = filterActiveItems(
        defaultSetting.language?.data,
        "status"
    );
    const filteredSaleSupport = filterActiveItems(
        defaultSetting.saleSupport?.data,
        "status"
    );
    const filteredPaymentMethod = filterActiveItems(
        defaultSetting.paymentMethod?.data,
        "status"
    );
    const filteredCurrency = filterActiveItems(
        defaultSetting.currency?.data,
        "status"
    );

    const [applicationsClass, applicationsText] = generateClassAndText(
        defaultSetting.applications?.data || [],
        "Application"
    );
    const [peripheralClass, peripheralText] = generateClassAndText(
        defaultSetting.peripheral?.data || [],
        "Péripheriques"
    );

    return (
        <div>
            {filteredTva.length === 0 ? (
                <p>
                    <strong className="text-danger">{t("VAT")} :</strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("VAT")} : </strong>
                    {generateTvaText(filteredTva)}
                </p>
            )}

            {filteredLanguage.length === 0 ? (
                <p>
                    <strong className="text-danger">{t("Language")} :</strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("Language")} : </strong>
                    {generateSpecificItemText(filteredLanguage, i18nextLng)}
                </p>
            )}

            {filteredSaleSupport.length === 0 ? (
                <p>
                    <strong className="text-danger">
                        {t("Sales support")} :
                    </strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("Sales support")} : </strong>
                    {generateSpecificItemText(filteredSaleSupport, i18nextLng)}
                </p>
            )}

            {filteredSaleMode.length === 0 ? (
                <p>
                    <strong className="text-danger">
                        {t("Sales method")} :
                    </strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("Sales method")} : </strong>
                    {generateSpecificItemText(filteredSaleMode, i18nextLng)}
                </p>
            )}
            {filteredPaymentMethod.length === 0 ? (
                <p>
                    <strong className="text-danger">
                        {t("Payment method")} :
                    </strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("Payment method")} : </strong>
                    {generateSpecificItemText(
                        filteredPaymentMethod,
                        i18nextLng
                    )}
                </p>
            )}
            {filteredCurrency.length === 0 ? (
                <p>
                    <strong className="text-danger">{t("Currency")} : </strong>
                    <span className="text-danger">{t("empty")}</span>
                </p>
            ) : (
                <p>
                    <strong>{t("Currency")} : </strong>
                    {generateItemText(filteredCurrency, "devise", "", t)}
                </p>
            )}
            <p>
                <strong>
                    <span
                        className={`font-weight-bold ${
                            applicationsClass || ""
                        }`}
                    >
                        {t("Applications")} :
                    </span>
                </strong>{" "}
                <span className={applicationsClass}>{t(applicationsText)}</span>
            </p>
            <p>
                <strong>
                    <span
                        className={`font-weight-bold ${peripheralClass || ""}`}
                    >
                        {t("Peripherals")} :
                    </span>
                </strong>{" "}
                <span className={peripheralClass}>{t(peripheralText)}</span>
            </p>
        </div>
    );
}
