import React from "react";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "reactstrap";

export function ModalHeaderComponent({ setIsOpen, isOpen }: any) {
    const { t } = useTranslation();

    return (
        <ModalHeader
            toggle={() => setIsOpen(!isOpen)}
            className="text-uppercase"
        >
            {t("Default Configuration Validation")}
        </ModalHeader>
    );
}
